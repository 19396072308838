import PageWrapper from './PageWrapper';
import { useMemo } from 'react';

const START_DEV = new Date('2014-07-07T00:00:00Z');
const YEAR_MILLIS = 31556952000;

const Home = () => {

  const dev_years = useMemo(() => Math.floor((Date.now() - START_DEV.getTime()) / YEAR_MILLIS), []);

  return (
    <PageWrapper forceReadableWidth>
      <h1>Bio</h1>
      <p>
        Delu. young/passionated software engineer / visionary with {dev_years} years experience in the IT domain
      </p>
      <p>
        I envision, conceptualize, build and publish high standards products/projects. I make any ideas become a reality. Currently, I'm a Backend Engineer
        at{' '}
        <a href="https://savagenationnft.com/" target="norel noopen">
          Savage Nation
        </a>
        .
      </p>

      <h3>About Savage Nation</h3>
      <p>
        Released in December of 2022, SN rapidly grew to be amongst the top collections on OpenSea. Trending in the top 3 for over a week, SN gained over 1900 ETH in total volume, only 12 days into its release. Read more about SN's lore {' '}
        <a href="https://savagenationnft.com/lore" target="norel noopen">
          here
        </a>
      </p>
    </PageWrapper>
  );
};

export default Home;
